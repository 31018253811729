import addDays from 'date-fns/addDays';
import addSeconds from 'date-fns/addSeconds';
import addMinutes from 'date-fns/addMinutes';
import addHours from 'date-fns/addHours';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInQuarters from 'date-fns/differenceInQuarters';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfDay from 'date-fns/endOfDay';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import getHours from 'date-fns/getHours';
import getSeconds from 'date-fns/getSeconds';
import getYear from 'date-fns/getYear';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import isSameDay from 'date-fns/isSameDay';
import isSameYear from 'date-fns/isSameYear';
import isSameMonth from 'date-fns/isSameMonth';
import isSameHour from 'date-fns/isSameHour';
import isValid from 'date-fns/isValid';
import dateFnsParse from 'date-fns/parse';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setMonth from 'date-fns/setMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import setSeconds from 'date-fns/setSeconds';
import setYear from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import isWithinInterval from 'date-fns/isWithinInterval';
import longFormatters from 'date-fns/_lib/format/longFormatters';
import defaultLocale from 'date-fns/locale/en-US';

var defaultFormats = {
    dayOfMonth: "d",
    fullDate: "PP",
    fullDateWithWeekday: "PPPP",
    fullDateTime: "PP p",
    fullDateTime12h: "PP hh:mm aaa",
    fullDateTime24h: "PP HH:mm",
    fullTime: "p",
    fullTime12h: "hh:mm aaa",
    fullTime24h: "HH:mm",
    hours12h: "hh",
    hours24h: "HH",
    keyboardDate: "P",
    keyboardDateTime: "P p",
    keyboardDateTime12h: "P hh:mm aaa",
    keyboardDateTime24h: "P HH:mm",
    minutes: "mm",
    month: "LLLL",
    monthAndDate: "MMMM d",
    monthAndYear: "LLLL yyyy",
    monthShort: "MMM",
    weekday: "EEEE",
    weekdayShort: "EEE",
    normalDate: "d MMMM",
    normalDateWithWeekday: "EEE, MMM d",
    seconds: "ss",
    shortDate: "MMM d",
    year: "yyyy",
};
var DateFnsUtils = /** @class */ (function () {
    function DateFnsUtils(_a) {
        var _b = _a === void 0 ? {} : _a, locale = _b.locale, formats = _b.formats;
        this.lib = "date-fns";
        this.locale = locale;
        this.formats = Object.assign({}, defaultFormats, formats);
    }
    // Note: date-fns input types are more lenient than this adapter, so we need to expose our more
    // strict signature and delegate to the more lenient signature. Otherwise, we have downstream type errors upon usage.
    DateFnsUtils.prototype.is12HourCycleInCurrentLocale = function () {
        if (this.locale) {
            return /a/.test(this.locale.formatLong.time());
        }
        // By default date-fns is using en-US locale with am/pm enabled
        return true;
    };
    DateFnsUtils.prototype.getFormatHelperText = function (format) {
        // @see https://github.com/date-fns/date-fns/blob/master/src/format/index.js#L31
        var longFormatRegexp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
        var locale = this.locale || defaultLocale;
        return format
            .match(longFormatRegexp)
            .map(function (token) {
            var firstCharacter = token[0];
            if (firstCharacter === "p" || firstCharacter === "P") {
                var longFormatter = longFormatters[firstCharacter];
                return longFormatter(token, locale.formatLong, {});
            }
            return token;
        })
            .join("")
            .replace(/(aaa|aa|a)/g, "(a|p)m")
            .toLocaleLowerCase();
    };
    DateFnsUtils.prototype.getCurrentLocaleCode = function () {
        var _a;
        return ((_a = this.locale) === null || _a === void 0 ? void 0 : _a.code) || "en-US";
    };
    DateFnsUtils.prototype.addSeconds = function (value, count) {
        return addSeconds(value, count);
    };
    DateFnsUtils.prototype.addMinutes = function (value, count) {
        return addMinutes(value, count);
    };
    DateFnsUtils.prototype.addHours = function (value, count) {
        return addHours(value, count);
    };
    DateFnsUtils.prototype.addDays = function (value, count) {
        return addDays(value, count);
    };
    DateFnsUtils.prototype.addWeeks = function (value, count) {
        return addWeeks(value, count);
    };
    DateFnsUtils.prototype.addMonths = function (value, count) {
        return addMonths(value, count);
    };
    DateFnsUtils.prototype.isValid = function (value) {
        return isValid(this.date(value));
    };
    DateFnsUtils.prototype.getDiff = function (value, comparing, unit) {
        switch (unit) {
            case "years":
                return differenceInYears(value, this.date(comparing));
            case "quarters":
                return differenceInQuarters(value, this.date(comparing));
            case "months":
                return differenceInMonths(value, this.date(comparing));
            case "weeks":
                return differenceInWeeks(value, this.date(comparing));
            case "days":
                return differenceInDays(value, this.date(comparing));
            case "hours":
                return differenceInHours(value, this.date(comparing));
            case "minutes":
                return differenceInMinutes(value, this.date(comparing));
            case "seconds":
                return differenceInSeconds(value, this.date(comparing));
            default: {
                return differenceInMilliseconds(value, this.date(comparing));
            }
        }
    };
    DateFnsUtils.prototype.isAfter = function (value, comparing) {
        return isAfter(value, comparing);
    };
    DateFnsUtils.prototype.isBefore = function (value, comparing) {
        return isBefore(value, comparing);
    };
    DateFnsUtils.prototype.startOfDay = function (value) {
        return startOfDay(value);
    };
    DateFnsUtils.prototype.endOfDay = function (value) {
        return endOfDay(value);
    };
    DateFnsUtils.prototype.getHours = function (value) {
        return getHours(value);
    };
    DateFnsUtils.prototype.setHours = function (value, count) {
        return setHours(value, count);
    };
    DateFnsUtils.prototype.setMinutes = function (value, count) {
        return setMinutes(value, count);
    };
    DateFnsUtils.prototype.getSeconds = function (value) {
        return getSeconds(value);
    };
    DateFnsUtils.prototype.setSeconds = function (value, count) {
        return setSeconds(value, count);
    };
    DateFnsUtils.prototype.isSameDay = function (value, comparing) {
        return isSameDay(value, comparing);
    };
    DateFnsUtils.prototype.isSameMonth = function (value, comparing) {
        return isSameMonth(value, comparing);
    };
    DateFnsUtils.prototype.isSameYear = function (value, comparing) {
        return isSameYear(value, comparing);
    };
    DateFnsUtils.prototype.isSameHour = function (value, comparing) {
        return isSameHour(value, comparing);
    };
    DateFnsUtils.prototype.startOfMonth = function (value) {
        return startOfMonth(value);
    };
    DateFnsUtils.prototype.endOfMonth = function (value) {
        return endOfMonth(value);
    };
    DateFnsUtils.prototype.startOfWeek = function (value) {
        return startOfWeek(value, { locale: this.locale });
    };
    DateFnsUtils.prototype.endOfWeek = function (value) {
        return endOfWeek(value, { locale: this.locale });
    };
    DateFnsUtils.prototype.getYear = function (value) {
        return getYear(value);
    };
    DateFnsUtils.prototype.setYear = function (value, count) {
        return setYear(value, count);
    };
    DateFnsUtils.prototype.date = function (value) {
        if (typeof value === "undefined") {
            return new Date();
        }
        if (value === null) {
            return null;
        }
        return new Date(value);
    };
    DateFnsUtils.prototype.toJsDate = function (value) {
        return value;
    };
    DateFnsUtils.prototype.parse = function (value, formatString) {
        if (value === "") {
            return null;
        }
        return dateFnsParse(value, formatString, new Date(), { locale: this.locale });
    };
    DateFnsUtils.prototype.format = function (date, formatKey) {
        return this.formatByString(date, this.formats[formatKey]);
    };
    DateFnsUtils.prototype.formatByString = function (date, formatString) {
        return format(date, formatString, { locale: this.locale });
    };
    DateFnsUtils.prototype.isEqual = function (date, comparing) {
        if (date === null && comparing === null) {
            return true;
        }
        return isEqual(date, comparing);
    };
    DateFnsUtils.prototype.isNull = function (date) {
        return date === null;
    };
    DateFnsUtils.prototype.isAfterDay = function (date, value) {
        return isAfter(date, endOfDay(value));
    };
    DateFnsUtils.prototype.isBeforeDay = function (date, value) {
        return isBefore(date, startOfDay(value));
    };
    DateFnsUtils.prototype.isBeforeYear = function (date, value) {
        return isBefore(date, startOfYear(value));
    };
    DateFnsUtils.prototype.isAfterYear = function (date, value) {
        return isAfter(date, endOfYear(value));
    };
    DateFnsUtils.prototype.isWithinRange = function (date, _a) {
        var start = _a[0], end = _a[1];
        return isWithinInterval(date, { start: start, end: end });
    };
    DateFnsUtils.prototype.formatNumber = function (numberToFormat) {
        return numberToFormat;
    };
    DateFnsUtils.prototype.getMinutes = function (date) {
        return date.getMinutes();
    };
    DateFnsUtils.prototype.getMonth = function (date) {
        return date.getMonth();
    };
    DateFnsUtils.prototype.getDaysInMonth = function (date) {
        return getDaysInMonth(date);
    };
    DateFnsUtils.prototype.setMonth = function (date, count) {
        return setMonth(date, count);
    };
    DateFnsUtils.prototype.getMeridiemText = function (ampm) {
        return ampm === "am" ? "AM" : "PM";
    };
    DateFnsUtils.prototype.getNextMonth = function (date) {
        return addMonths(date, 1);
    };
    DateFnsUtils.prototype.getPreviousMonth = function (date) {
        return addMonths(date, -1);
    };
    DateFnsUtils.prototype.getMonthArray = function (date) {
        var firstMonth = startOfYear(date);
        var monthArray = [firstMonth];
        while (monthArray.length < 12) {
            var prevMonth = monthArray[monthArray.length - 1];
            monthArray.push(this.getNextMonth(prevMonth));
        }
        return monthArray;
    };
    DateFnsUtils.prototype.mergeDateAndTime = function (date, time) {
        return this.setSeconds(this.setMinutes(this.setHours(date, this.getHours(time)), this.getMinutes(time)), this.getSeconds(time));
    };
    DateFnsUtils.prototype.getWeekdays = function () {
        var _this = this;
        var now = new Date();
        return eachDayOfInterval({
            start: startOfWeek(now, { locale: this.locale }),
            end: endOfWeek(now, { locale: this.locale }),
        }).map(function (day) { return _this.formatByString(day, "EEEEEE"); });
    };
    DateFnsUtils.prototype.getWeekArray = function (date) {
        var start = startOfWeek(startOfMonth(date), { locale: this.locale });
        var end = endOfWeek(endOfMonth(date), { locale: this.locale });
        var count = 0;
        var current = start;
        var nestedWeeks = [];
        while (isBefore(current, end)) {
            var weekNumber = Math.floor(count / 7);
            nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
            nestedWeeks[weekNumber].push(current);
            current = addDays(current, 1);
            count += 1;
        }
        return nestedWeeks;
    };
    DateFnsUtils.prototype.getYearRange = function (start, end) {
        var startDate = startOfYear(start);
        var endDate = endOfYear(end);
        var years = [];
        var current = startDate;
        while (isBefore(current, endDate)) {
            years.push(current);
            current = addYears(current, 1);
        }
        return years;
    };
    return DateFnsUtils;
}());

export default DateFnsUtils;
